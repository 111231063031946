import React from 'react';
import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'definition';
        font-style: normal;
        font-display: swap;
        src: url('/static/manrope.woff2') format('woff2');
      }
      `}
  />
)

export default Fonts