import React from "react"
import { ChakraProvider } from "@chakra-ui/react"
import { AnimatePresence, AnimateSharedLayout } from "framer-motion"
import Fonts from "./src/fonts"
import theme from "./src/theme"
export const wrapPageElement = ({ element }) => (
  <ChakraProvider theme={theme}>
    <Fonts />
    <AnimatePresence />
    <AnimateSharedLayout>{element}</AnimateSharedLayout>
    <AnimatePresence />
  </ChakraProvider>
)
